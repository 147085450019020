import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import gql from "graphql-tag"
import SEO from "../components/seo"

import Layout from "../components/layout"
import kidd from "../images/detaljen/kidd.png"

const Fonts = createGlobalStyle`
    @font-face {
      font-family: "Avenir";
      src: url("../fonts/medium.otf");
    }
`

const Container = styled.div`
  background-color: #121212;
  display: flex;
  height: 100%;

  align-items: flex-start;
  justify-content: center;
`

const Content = styled.div`
  background-color: #121212;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  min-height: 100vh;
`

const BigTitle = styled.p`
  font-size: 30px;
  text-align: center;
  font-family: "Avenir";
  font-weight: 700;
  color: #fdde68;
  align-self: center;
  margin-top: 20px;
  letter-spacing: 3px;

  @media (min-width: 500px) {
    font-size: 2.5rem;
  }
`

const SubTitle = styled.p`
  font-size: 30px;
  font-family: "Avenir";
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
`

const SmallText = styled.p`
  font-size: 20px;
  font-family: "Avenir";
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
`

const FirstSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const TextSection = styled.div`
  align-self: center;
  margin-top: 50px;
`

const FirstText = styled.p`
  font-family: "Avenir" !important;
  font-size: 1.3rem !important;
  line-height: 1.3;
  color: #fff;
  max-width: 95%;
  color: #fff;
  text-align: center;
`

const CTA = styled(props => <a {...props} />)`
  width: 240px;
  height: 55px;
  margin-top: 25px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdde68;
  border-radius: 25px;
  font-size: 25px;
  font-family: "Avenir";
  font-weight: 700;
  color: #333;
  outline: none !important;
  box-shadow: none !important;
  border: none;
  text-decoration: none;
`

const Episodes = styled.div`
  margin-top: 50px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Episode = styled.div`
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

const EpisodeTitle = styled.div`
  font-size: 25px;
  text-align: center;
  font-family: "Avenir";
  font-weight: 700;
  color: #fff;
  align-self: center;
  margin-top: 20px;
  letter-spacing: 3px;

  @media (min-width: 500px) {
    font-size: 2.5rem;
  }
`

const EpisodeNumber = styled.p`
  font-size: 20px;
  text-align: center;
  font-family: "Avenir";
  max-width: 90%;
  font-weight: 400;
  color: #fff;
  align-self: center;
  margin-top: 20px;
  letter-spacing: 3px;

  @media (min-width: 500px) {
    font-size: 1.5rem;
  }
`

const ImageContainer = styled.div`
  margin-top: 25px;
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
`

const Image = styled.div`
  align-self: center;
  width: 250px;
  height: 800px;
  background-repeat: no-repeat;
  background-size: contain;

  @media (min-width: 500px) {
    width: 250px;
    height: 500px;
  }

  @media (min-width: 650px) {
    width: 320px;
    height: 700px;
  }

  @media (min-width: 750px) {
    width: 370px;
    height: 700px;
  }
`

// -------------

// Images extended from Image
const Image1 = styled(Image)`
  align-self: center;
  background-image: url(${kidd});
`

export default function Detaljen(props) {
  const handleClick = () => {
    if (window.analytics) {
      window.analytics.track("Clicked Detaljen follow")
    }
    window.location.href = "https://instagram.com/join_vibe"
  }

  return (
    <Container>
      <SEO title="#QueueDetaljen" />

      <Content>
        <FirstSection>
          <TextSection>
            <BigTitle>
              <p style={{ marginBottom: 0, marginTop: 0 }}>#QueueDetaljen</p>
            </BigTitle>
          </TextSection>
          <FirstText>
            Vi tager dig bag kulliserne af kendte kunstneres sangtekster🤩
          </FirstText>
          <CTA onClick={() => handleClick()}>Følg med👉</CTA>
        </FirstSection>
        <Episodes>
          <EpisodeTitle>Kidd - på tirsdag</EpisodeTitle>
          <Episode>
            <ImageContainer>
              <Image1 />
            </ImageContainer>
          </Episode>
        </Episodes>
      </Content>
    </Container>
  )
}
